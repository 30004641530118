import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/shang-pin-fen-lei/list',
        method: 'post',
        data
    })
}
//获取树结构
export function mainTree() {
    return request({
        url: '/shang-pin-fen-lei/main-tree',
        method: 'get'
    });
}
//获取商品分类列表
export function getFenLeiSelector(data) {
    return request({
        url: '/shang-pin-fen-lei/get-selector',
        method: 'post',
        data
    })
}


//添加保存
export function add(data) {
    return request({
        url: '/shang-pin-fen-lei/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/shang-pin-fen-lei/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/shang-pin-fen-lei/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/shang-pin-fen-lei/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/shang-pin-fen-lei/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/shang-pin-fen-lei/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/shang-pin-fen-lei/query',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'shang-pin-fen-lei-list',
    component: () => import('@/view/spgl/spfl/ShangPinFenLeiList'),
    name: 'ShangPinFenLeiList',
    meta: {title: '商品分类', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'shang-pin-fen-lei-query',
    component: () => import('@/view/spgl/spfl/ShangPinFenLeiQuery'),
    name: 'ShangPinFenLeiQuery',
    meta: {title: '商品分类查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'shang-pin-fen-lei-remind-list',
    component: () => import('@/view/spgl/spfl/ShangPinFenLeiRemindList'),
    name: 'ShangPinFenLeiRemindList',
    meta: {title: '商品分类提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"商品分类基础列表","methodUrl":"/shang-pin-fen-lei/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"商品分类添加保存","methodUrl":"/shang-pin-fen-lei/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"商品分类获取编辑数据","methodUrl":"/shang-pin-fen-lei/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"商品分类获取详情数据","methodUrl":"/shang-pin-fen-lei/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"商品分类编辑保存","methodUrl":"/shang-pin-fen-lei/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"商品分类删除","methodUrl":"/shang-pin-fen-lei/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"商品分类批量删除","methodUrl":"/shang-pin-fen-lei/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"商品分类独立查询","methodUrl":"/shang-pin-fen-lei/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
