<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="parentId" label="父分类ID" />
            <x-detail-item prop="name" label="分类名称" />
            <x-detail-item prop="level" label="树结构等级" />
            <x-detail-item prop="suoShuXingYe" label="所属行业" />
            <x-detail-item prop="fenLeiBM" label="分类编码" />
            <x-detail-item prop="suoShuMenDian" label="所属门店" />
            <x-detail-item prop="shiFuShanChu" label="是否删除" />
            <x-detail-item prop="sort" label="同级排序" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/spgl/ShangPinFenLei";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                parentId: "", // 父分类ID
                name: "", // 分类名称
                level: "", // 树结构等级
                suoShuXingYe: "", // 所属行业
                fenLeiBM: "", // 分类编码
                suoShuMenDian: "", // 所属门店
                shiFuShanChu: "", // 是否删除
                sort: "", // 同级排序
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>